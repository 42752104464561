<template>
  <div>
    <svg width="73px" height="62px" viewBox="0 0 73 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <!-- Generator: Sketch 63 (92445) - https://sketch.com -->
      <title>24/7 Customer Servic</title>
      <desc>Created with Sketch.</desc>
      <g id="工作原理" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Affiliate-Program5" transform="translate(-1209.000000, -1834.000000)">
          <g id="编组-7备份" transform="translate(391.000000, 1463.000000)">
            <g id="编组-9备份-5" transform="translate(820.000000, 364.000000)">
              <g id="24/7-Customer-Servic" transform="translate(0.000000, 9.000000)">
                <path d="M61.1809083,38.8248622 C61.6068649,38.9396806 62.0480457,39 62.5,39 C66.0898509,39 69,35.1944204 69,30.5 C69,25.8055796 66.0898509,22 62.5,22" id="路径" stroke="#000000" stroke-width="2.2" fill="#D8D8D8" stroke-linecap="round"></path>
                <path d="M9.27038648,43.4481756 C14.2090531,52.144867 23.4691989,58 34.0782696,58 C49.8765167,58 62.6835264,45.0162577 62.6835264,29 C62.6835264,12.9837423 49.8765167,0 34.0782696,0 C23.0331441,0 13.4501095,6.34634081 8.68352637,15.6383729" id="路径" stroke="#000000" stroke-width="2.2" stroke-linecap="round"></path>
                <ellipse id="椭圆形" fill="#D8D8D8" cx="6.5" cy="30.5" rx="6.5" ry="8.5"></ellipse>
                <ellipse id="椭圆形" stroke="#000000" stroke-width="2.2" stroke-linecap="round" cx="6.5" cy="30.5" rx="6.5" ry="8.5"></ellipse>
                <path d="M38.2526996,32.0014718 C39.6052778,33.237792 41.4572693,34 43.5,34 C45.9135413,34 48.0608095,32.9359531 49.4326719,31.2830501" id="路径" stroke="#000000" stroke-width="2.2" stroke-linecap="round"></path>
                <path d="M8.83224446,41.5383471 C12.7246675,43.0815509 18.1085461,44.03646 24.0565618,44.03646 C30.9798716,44.03646 37.1388741,42.742725 41.0712109,40.7333968" id="路径" stroke="#000000" stroke-width="2.2" stroke-linecap="round" transform="translate(24.951728, 42.384928) rotate(12.000000) translate(-24.951728, -42.384928) "></path>
                <ellipse id="椭圆形备份-4" fill="#000000" cx="35.5" cy="20" rx="2.5" ry="3"></ellipse>
                <ellipse id="椭圆形备份-6" fill="#000000" cx="49.5" cy="20" rx="2.5" ry="3"></ellipse>
                <ellipse id="椭圆形备份-7" fill="#000000" cx="41.5" cy="44" rx="2.5" ry="3"></ellipse>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "created-with-sketch",
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {}
}
</script>

<style scoped lang=''>

</style>
