<script setup>
import { defineComponent, reactive, toRefs, ref, computed } from "vue";
import examine from "@/assets/affiliateProgram/examine.png";
import { ElMessage } from "element-plus";
import { apply } from "@/api/affiliate.js";
import Store from "store";
//晓燕
let validatePass = (rule, value, callback) => {
  if (!value) {
    callback(
      new Error("You must agree to our Terms of Service before submit to 99diy")
    );
  } else {
    callback();
  }
};

let validate = (rule, value, callback) => {
  let obj = promotionAddress.list.find((e) => !!e.show);
  if (!!obj && !!obj.url) {
    let path =
      /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i;
    if (!path.test(obj.url)) {
      callback(new Error("Please fill in a valid URL"));
    } else {
      callback();
    }
  } else {
    callback(new Error("Please fill out publicly accessible URL"));
  }
};




const emit = defineEmits(["back", "update"]);
let ruleFormRef = ref("");

let ruleForm = reactive({
  name: "",
  marketingExperience: undefined,
  channel: [],
  region: "",
  more: "",
  receiveEmail: false,
  league: false,
});
let rules = reactive({
  name: [
    {
      required: true,
      message: "Please enter full name",
      trigger: "blur",
    },
  ],
  marketingExperience: [
    {
      required: true,
      message: "Please pick an option!",
      trigger: "change",
    },
  ],
  channel: [
    {
      required: true,
      message: "Please choose at least one option!",
      trigger: "change",
    },

    { validator: validate, trigger: "blur" },
  ],
  region: [
    {
      required: true,
      message: "Please pick an option!",
      trigger: "blur",
    },
  ],
  league: [
    {
      validator: validatePass,
      trigger: "blur",
    },
  ],
});

//运营经验
let experience = reactive({
  list: [
    {
      name: "Beginner",
      describe: "New to affiliate marketing",
    },
    {
      name: "Intermediate",
      describe: "Basic knowledge, but never earned commission",
    },
    {
      name: "Advanced",
      describe: "Some experience, earned some commission",
    },
    {
      name: "Expert",
      describe: "Extensive experience and steady income from commissions",
    },
  ],
});

// 推广地方
let promotionAddress = reactive({
  list: [
    {
      name: "My website",
      url: "",
      show: false,
    },
    {
      name: "Social media",
      url: "",
      show: false,
    },
    {
      name: "Podcasting or online courses",
      url: "",
      show: false,
    },
    {
      name: "My blog",
      url: "",
      show: false,
    },
    {
      name: "Others",
      url: "",
      show: false,
    },
  ],
});

//推广区域
let regionObj = reactive({
  list: [
    "North America",
    "South America",
    "Europe",
    "Asia",
    "Africa",
    "Middle East",
    "Australia & Oceania",
  ],
});

// 推广渠道url显示
let checkboxChange = (val) => {
  val.show = !val.show;
};

//校验规则
let getApply = () => {
  ruleFormRef.value.validate(async (val) => {
    if (val) {
      let list = promotionAddress.list.filter((item) => !!item.show);
      try {
        const { message } = await apply({
          name: ruleForm.name,
          marketLevel: ruleForm.marketingExperience,
          channel: list,
          region: ruleForm.region,
          more: ruleForm.more,
        });
        ElMessage.success(message);
        emit("update");
        cancel();
      } catch (error) {
        ElMessage.error(error);
      }
    }
  });
};

//初始化
let cancel = () => {
  emit("back");
  ruleFormRef.value.resetFields();
  ruleForm = {
    name: "",
    marketingExperience: undefined,
    channel: [],
    region: "",
    more: "",
    receiveEmail: false,
    league: false,
  };
  promotionAddress.list.forEach((item) => {
    item.show = false;
  });
};


//协议跳转
let termsOfService = ()=> {

    // 获取 token
  let token
  if (process.env.VUE_APP_CUSTOM_ENV === 'development') {
      token = process.env.VUE_APP_TOKEN
  } else {
      token = Store.get('diyToken')
  }
  window.open(process.env.VUE_APP_URL_HOME+ '/terms-of-service' + `?diyToken=${token}`)
  
}

</script>

<template>
  <div class="flex form_box">
    <div class="right">
      <div class="title">Affiliate Program</div>
      <div class="describe mb_50">
        Please fill out our application form. We'll review it and let you know
        via email whether you're approved.
      </div>

      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
        label-position="top"
      >
        <el-form-item label="Full name" prop="name">
          <el-input v-model="ruleForm.name" clearable placeholder="Full name" />
        </el-form-item>

        <el-form-item
          label="1. What is your experience in affiliate marketing?"
          prop="marketingExperience"
        >
          <el-select
            v-model="ruleForm.marketingExperience"
            placeholder="Select"
            clearable
          >
            <el-option
              style="height: auto"
              :label="item.name"
              :value="item.name"
              v-for="(item, index) in experience.list"
              :key="index"
            >
              <div class="name_select">{{ item.name }}</div>
              <div class="describe_select">{{ item.describe }}</div>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="2. Where do you plan to promote 99diy?"
          prop="channel"
        >
          <div class="describe mb_10">
            The URLs must be live and publicly accessible
          </div>
          <el-checkbox-group v-model="ruleForm.channel">
            <el-checkbox
              @change="checkboxChange(item)"
              :label="item.name"
              v-for="(item, index) in promotionAddress.list"
              :key="index"
              style="width: 100%"
            >
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <div style="line-height: 50px">{{ item.name }}</div>

                <el-input
                  v-if="item.show"
                  v-model="item.url"
                  style="width: 300px"
                ></el-input>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="3. Primary region" prop="region">
          <div class="describe mb_10">
            Select a region you primarily operate in
          </div>
          <el-select
            v-model="ruleForm.region"
            placeholder="please select your zone"
            clearable
          >
            <el-option
              :label="item"
              :value="item"
              v-for="(item, index) in regionObj.list"
              :key="index"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="4. Tell us more about you (optional)">
          <div class="describe">
            Please provide us relevant information about your business or
          </div>
          <div class="describe mb_10">
            marketing capabilities to help us better evaluate your application
          </div>
          <el-input v-model="ruleForm.more" type="textarea" />
        </el-form-item>

        <el-form-item prop="league">
          <el-checkbox v-model="ruleForm.league" name="type">
            I agree to 99diy’s affiliate program
            <span class="terms_of_service"  @click.prevent="termsOfService">Terms of Service</span>
          </el-checkbox>

          <el-checkbox
            v-model="ruleForm.receiveEmail"
            label="I'd like to receive emails about the affiliate program.
              You can unsubscribe at any time."
            name="type"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getApply">Submit</el-button>
          <el-button type="info" @click="cancel">Cancel</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="image">
      <el-image :src="examine" style="width: 100%; height: 100%"></el-image>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(.el-form-item__label) {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
:deep(.el-select) {
  width: 100%;
}
:deep(.el-checkbox) {
  width: 100%;
}
:deep(.el-checkbox__label) {
  width: 100%;
}

:deep(.el-form-item) {
  margin-bottom: 82px;
}
:deep(.el-form-item__error) {
  font-size: 16px;
  font-weight: 400;
  color: #ff0000;
}
.mb_30 {
  margin-bottom: 30px;
}
.mb_50 {
  margin-bottom: 50px;
}
.mb_10 {
  margin-bottom: 10px;
}
.form_box {
  width: 1120px;
}
.image {
  width: 287;
  height: 220px;
  margin-right: 97px;
  margin-top: 102px;
}
.right {
  margin-right: 130px;
  padding-left: 105px;
  flex: 1;
  .title {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 50px;
    margin-top: 60px;
    margin-bottom: 6px;
  }
  .describe {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 26px;
  }
}

.describe_select {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 20px;
  padding-bottom: 15px;
}
.name_select {
  line-height: 20px;
  padding-top: 15px;
  color: #000;
}
.terms_of_service {
  color: #1eab7f;
}
</style>
