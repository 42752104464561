<script setup>
import { defineComponent, reactive, toRefs, ref } from "vue";
import ClipboardJS from "clipboard";
import { ElMessage } from "element-plus";

let props = defineProps({
  promotionCode:{
    type: String,
    default:''
  }
})


let copyUrl = () => {
  let clipboard = new ClipboardJS(".btn");
  clipboard.on("success", function (e) {
    ElMessage.success("Copy Success");
    clipboard.destroy();
  });

  clipboard.on("error", function (e) {
    ElMessage.error("Copy Error");
    clipboard.destroy();
  });
};
</script>

<template>
  <div class="w1120">
    <div class="title">Affiliate Program Summary</div>
    <div class="flex block">
      <div class="link">
        <div class="flex flex_space">
          <div class="link_text">Link</div>
          <!-- <div class="link_share">
            Share to
            <a href="https://www.facebook.com/99DIY-103111905599078/" target="_black"><i class="iconfont icon-facebook icon"></i></a>
            <a href="https://www.instagram.com/accounts/login/?next=/99diy_customprinting/" target="_black"><i class="iconfont icon-instagram icon"></i></a>
            <a href="https://www.youtube.com/channel/UCtoKxmerRGfgd3fEUAF3HJA" target="_black"><i class="iconfont icon-youtube icon"></i></a>
          </div> -->
        </div>
        <div class="link_url">Share your Link to refer people to 99diy.</div>
        <div>
          <el-input readonly v-model="promotionCode" id="foo"></el-input>
        </div>
        <el-button
          data-clipboard-target="#foo"
          class="link_btn btn"
          type="primary"
          @click="copyUrl"
          >Copy</el-button
        >
      </div>
      <div class="reward_offers">
        <div class="reward_offers_title">Reward offers</div>
        <div class="reward_offers_describe">
          Earn these Rewards for referring people to 99diy.
        </div>
        <div>
          <div class="flex">
            <div class="reward_offers_border"></div>
            <div class="reward_offers_text">
              Earn 10% commission of your customer's orders for the first 12
              months
            </div>
          </div>
          <div class="flex">
            <div class="reward_offers_border"></div>
            <div class="reward_offers_text">
              $ 10.00 coupons for your referred customers
            </div>
          </div>
          <div class="flex">
            <div class="reward_offers_border"></div>
            <div class="reward_offers_text">
              5000 Coins for your referred customers
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
a {
  text-decoration: none;
}
.icon {
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.3);
}
.w1120 {
  width: 1120px;
}
.flex_space {
  justify-content: space-between;
}
.title {
  font-size: 36px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 50px;
  margin-top: 50px;
  margin-bottom: 30px;
}
.link {
  width: 499px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  padding: 24px;
  &_text {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
    margin-bottom: 8px;
  }
  &_share {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
  }
  &_url {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 24px;
    margin-bottom: 20px;
  }
  &_btn {
    margin-top: 20px;
  }
}
.reward_offers {
  flex: 1;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 24px;
  &_title {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
    margin-bottom: 8px;
  }
  &_describe {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 24px;
    margin-bottom: 20px;
  }
  &_border {
    width: 6px;
    height: 6px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 100%;
    margin-right: 10px;
    margin-top: 7px;
  }
  &_text {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    margin-bottom: 15px;
  }
}



  @media screen and (max-width: 1250px) {
    .block {
      display: block;
    }
    .link {
      width: 100%;
      margin-bottom: 20px;
    }
  }
</style>
