<script setup>
import { defineComponent, reactive, toRefs, ref, watch } from "vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import VChart from "vue-echarts";

use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

let emit = defineEmits(["changeType"]);

watch(
  () => props.freezeMoney,
  () => {
    option.value.series[0].data = props.settleMoney;
    option.value.series[1].data = props.freezeMoney;
    option.value.xAxis.data = props.times;
  },
  {
    deep: true,
  }
);

let props = defineProps({
  totalObj: {
    type: Object,
    default: () => ({}),
  },
  freezeMoney: {
    type: Array,
    default: () => [],
  },
  settleMoney: {
    type: Array,
    default: () => [],
  },
  times: {
    type: Array,
    default: () => [],
  },
});

let option = ref({
  tooltip: {
    trigger: "axis",
  },

  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },

  xAxis: {
    // type: "category",
    boundaryGap: false,

    data: props.times,
  },
  yAxis: {
    type: "value",
    axisLabel: {
      formatter: "$ {value} ",
    },
  },
  series: [
    {
      name: "Earned",
      type: "line",
      //   stack: "Total",
      color: "#FF2379",
      smooth: true,
      data: props.settleMoney,
    },
    {
      name: "Pending",
      type: "line",
      //   stack: "Total",
      color: "#4BD9AD",
      smooth: true,
      data: props.freezeMoney,
    },
  ],
});

let optionChange = (e) => {
  emit("changeType", e);
};

let time = reactive({
  options: [
    {
      value: "1",
      label: "Last 7 Days",
    },
    {
      value: "2",
      label: "Last 30 Days",
    },
    {
      value: "3",
      label: "Last 90 Days",
    },
    {
      value: "4",
      label: "Last 180 Days",
    },
  ],
  value: "1",
});
</script>

<template>
  <div class="income w1120">
    <div class="income_title">Total rewards you earned</div>
    <div class="flex" style="flex-wrap: wrap;">
      <div class="box">
        <div class="box_num">{{ totalObj.subordinate.activated || 0 }}</div>
        <div class="box_text">Valid Customers</div>
      </div>
      <div class="box">
        <div class="box_num">{{ totalObj.subordinate.expired || 0 }}</div>
        <div class="box_text">Invalid Customers</div>
      </div>
      <div class="box">
        <div class="box_num">{{ totalObj.subordinate.unactivated || 0 }}</div>
        <div class="box_text">Unactivated</div>
      </div>
      <div class="box">
        <div class="box_num">{{ totalObj.baseInfo.order_number || 0 }}</div>
        <div class="box_text">Orders</div>
      </div>
      <div class="box">
        <div class="box_num">
          {{ `$ ${props.totalObj.baseInfo.commission_freeze || "0.00"}` }}
        </div>
        <div class="box_text">Pending</div>
      </div>
      <div class="box">
        <div class="box_num">
          {{ `$ ${props.totalObj.baseInfo.commission_settle || "0.00"}` }}
        </div>
        <div class="box_text">Earned</div>
      </div>
    </div>

    <v-chart class="chart" :option="option" />

    <div class="chart_flex">
      <div class="chart_color">
        <div class="chart_color_earned">Earned</div>
        <div class="chart_color_pending">Pending</div>
      </div>
      <div class="chart_time">
        <el-select
          v-model="time.value"
          placeholder="Select"
          @change="optionChange"
        >
          <el-option
            v-for="item in time.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.chart {
  width: 1070px;
  height: 400px;
  &_flex {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  &_color {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 40px;
    display: flex;
    &_earned {
      margin-right: 30px;
      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #ff2379;
        border-radius: 2px;
        margin-right: 12px;
      }
    }
    &_pending {
      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #4bd9ad;
        border-radius: 2px;
        margin-right: 12px;
      }
    }
  }
}
.w1120 {
  width: 1120px;
}
.income {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-top: 20px;
  &_title {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
    margin-bottom: 20px;
  }
}
.box {
  width: 162px;
  // height: 100px;
  padding: 20px 0 ;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-right: 19px;
  margin-bottom: 10px;

  &:nth-child(6) {
    margin-right: 0;
  }
  &_num {
    font-size: 28px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    // margin-top: 20px;
  }
  &_text {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 22px;
  }
}



  @media screen and (max-width: 1400px) {
   
  }


</style>
