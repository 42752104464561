<script setup>
import { defineComponent, reactive, toRefs } from "vue";

const emit = defineEmits(["applyNow"]);

const props = defineProps({
  examine: {
    type: Object,
    default: () => ({}),
  },
});

// 前往填写审核表
let applyNow = () => {
  emit("applyNow");
};
</script>
<template>
  <div class="flex_center">
    <div class="detail">
      <div class="detail_title">More Details</div>
      <div class="detail_describe">
        <div class="mb_10 flex">
          <div class="detail_describe_dot"></div>
          <div>
            Your commission is calculated based on your final payment amount
            (not the customer's retail price) minus shipping amount.
          </div>
        </div>
        <div class="mb_10 flex">
          <div class="detail_describe_dot"></div>
          <div>
            In order for a referral to be connected to your account, they need
            to sign up to 99diy within 30 days of opening your link
          </div>
        </div>
        <div class="mb_10 flex">
          <div class="detail_describe_dot"></div>
          <div>
            An affiliate period lasts 12 months after your referred customer's
            first order
          </div>
        </div>
        <div class="mb_10 flex">
          <div class="detail_describe_dot"></div>
          <div>You'll receive commission after an order has received</div>
        </div>
        <div>
          <div class="flex">
            <div class="detail_describe_dot"></div>
            Minimum withdrawal sum is $25. Payments are made in USD via PayPal
            in 1-3 business days
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex_center">
    <div class="form">
      <!-- 审核中 -->
      <template v-if="Number(examine.status) === 0">
        <div class="form_title">From now on</div>
        <div class="form_title">lay down and earn money</div>
      </template>
      <!-- 未审核 -->
      <template v-else>
        <div class="form_title">
          Can't wait to experience the 99diy Affiliate Program
        </div>
        <el-button class="form_btn" @click="applyNow"
          >Apply now <i class="iconfont icon-youjiantou"></i
        ></el-button>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.flex_center {
  display: flex;
  justify-content: center;
}
.mb_10 {
  margin-bottom: 10px;
}
.detail {
  width: 1000px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  margin-top: 50px;
  padding: 30px;
  &_title {
    font-size: 32px;
    font-weight: 600;
    color: #000000;
    line-height: 45px;
    margin-bottom: 30px;
  }
  &_describe {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 28px;

    &_dot {
      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        line-height: 20px;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0.5);
        margin-right: 10px;
      }
    }
  }
}
.form {
  width: 1000px;
  background: linear-gradient(180deg, #ff2379 0%, #012f9b 100%);
  border-radius: 10px;
  margin-top: 50px;
  padding: 53px 40px;
  margin-bottom: 100px;
  text-align: center;
  &_title {
    font-size: 36px;
    font-weight: 600;
    color: #ffffff;
    line-height: 56px;
    text-align: center;
  }
  &_btn {
    font-size: 16px;
    font-weight: 500;
    color: #f62694;
    line-height: 22px;
    margin-top: 50px;
  }
}
</style>
