<template>
  <div>
    <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <!-- Generator: Sketch 63 (92445) - https://sketch.com -->
      <title>Branding</title>
      <desc>Created with Sketch.</desc>
      <g id="工作原理" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Affiliate-Program5" transform="translate(-803.000000, -1835.000000)">
          <g id="编组-7备份" transform="translate(391.000000, 1463.000000)">
            <g id="编组-9备份-4" transform="translate(410.000000, 364.000000)">
              <g id="Branding" transform="translate(2.000000, 8.000000)">
                <circle id="椭圆形" stroke="#000000" stroke-width="2.2" cx="30" cy="30" r="28.9"></circle>
                <path d="M19.6970266,29.4060099 L19.6970266,18.6002029 C19.8869721,15.3724086 21.4918191,13.7585115 24.5115678,13.7585115 C27.5313165,13.7585115 31.4815209,13.7585115 36.362181,13.7585115 C39.065283,14.1692791 40.4168341,16.0368981 40.4168341,19.3613687 C40.4168341,22.6858392 40.4168341,26.034053 40.4168341,29.4060099" id="路径-14" stroke="#000000" stroke-width="2.2" stroke-linecap="round"></path>
                <polygon id="路径-15" fill="#D6D6D6" points="18.5854627 32.6835264 26.6890194 32.6835264 30 24.3573375 33.5168328 32.6835264 41.3076823 32.6835264 35.2697819 38.0519331 37.3562002 46.4816124 29.9465725 41.9580582 23.2965134 46.4816124 25.0743769 38.0519331"></polygon>
                <polygon id="路径-15" stroke="#000000" stroke-width="2.2" stroke-linejoin="round" points="18.5854627 32.6835264 26.6890194 32.6835264 30 24.3573375 33.5168328 32.6835264 41.3076823 32.6835264 35.2697819 38.0519331 37.3562002 46.4816124 29.9465725 41.9580582 23.2965134 46.4816124 25.0743769 38.0519331"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "branding",
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {}
}
</script>

<style scoped lang=''>

</style>
