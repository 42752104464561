import request from "@/utils/request.js";

// 当前申请状态
export function check() {
  return request({
    url: "/affiliate/check",
    method: "get",
  });
}

/**
   *  name, 姓名
      marketLevel, 营销经验等级
      channel,  推广渠道
      [
        {
          name 渠道名称
          url  url地址
        }
      ]
      region, 推广区域
      more 更多信息
   * @param {*} param0 
   * @returns 
   */
// 申请推广人
export function apply({ name, marketLevel, channel, region, more }) {
  return request({
    url: "/affiliate/apply",
    method: "POST",
    data: {
      name,
      marketLevel,
      channel,
      region,
      more,
    },
  });
}

/**
 *
 * @param {*} param0
 * @returns
 *
 * type
 * 1:最近7天,2:最近30天,3:最近90天,4:最近180天
 */
// 申请推广人

export function totalData({ type }) {
  return request({
    url: "/affiliate/totalData",
    method: "GET",
    params: {
      type,
    },
  });
}

//下线列表
/**
 * 
 * @param {} param0
 *type 1已激活,2未激活,3已失效(超12个月)默认传1 
  email 要查询的用户邮箱
 * @returns 
 */

export function referList({ type, email, page }) {
  return request({
    url: "/affiliate/referList",
    method: "GET",
    params: {
      type,
      email,
      page,
    },
  });
}
