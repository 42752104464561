<template>
  <div class="head">
    <div class="head_left">
      <h1 class="head_left_title">Affiliate Program</h1>
      <div class="head_left_description">
        Join 99diy Affiliate Program and get 10% commission from each order your
        customers place.
      </div>
      <el-button class="head_left_btn" @click="setApplyNow">Apply now</el-button>
    </div>
    <div class="head_right">
      <el-image
        :src="img"
        alt="Join 99diy Affiliate Program and get 5% commission from each order your
          customers place."
      ></el-image>
    </div>
  </div>
  <div style="display: flex;justify-content: center;">
    <div class="commission">
      <div class="commission_left">
        <el-image
          :src="img2"
          alt="12 consecutive months of order commission income"
        ></el-image>
      </div>
      <div class="commission_right">
        <div class="commission_right_title">
          12 consecutive months of order commission income
        </div>
        <div class="commission_right_description">
          You can introduce 99diy to people who may be interested in POD or
          Dropshipping. Start earning from the first order your referred customers makes,
          and keep receiving commission for 12 months.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup> 
import img from "@/assets/affiliateProgram/1.png";
import img2 from "@/assets/affiliateProgram/2.png";


const emit = defineEmits(['applyNow'])

let setApplyNow = () => {
  emit('applyNow')
}
</script>

<style scoped lang="scss">
h1 {
  margin: 0;
}
.head {
  display: flex;
  &_left {
    flex: 1;
    margin-top: 100px;
    margin-left: 67px;
    &_title {
      font-size: 36px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 50px;
      margin-bottom: 20px;
    }
    &_description {
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
      margin-bottom: 40px;
    }
    &_btn {
      background: #FF2379;
      border-radius: 4px;
      color: #fff;
      &:hover {
      border:1px solid  transparent;

      }
    }
  }
  &_right {
    width: 410px;
    height: 330px;
    margin-top: 34px;
    margin-left: 80px;
    margin-right: 89px;
  }
}
.commission {
  display: flex;
  width: 992px;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 50px;
  &_left {
    width: 225px;
    display: flex;
    align-items: flex-end;
    margin-top: 26px;
  }
  &_right {
    flex: 1;
    padding: 50px;
    &_title {
      font-size: 32px;
      font-weight: 600;
      color: #000000;
      line-height: 45px;
      margin-bottom: 20px;
    }
    &_description {
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 30px;
    }
  }
}
</style>
