<template>
  <div>
    <svg width="84px" height="59px" viewBox="0 0 84 59" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <!-- Generator: Sketch 63 (92445) - https://sketch.com -->
      <title>Free Shipping Worldwide</title>
      <desc>Created with Sketch.</desc>
      <g id="工作原理" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Affiliate-Program5" transform="translate(-803.000000, -1576.000000)">
          <g id="编组-7备份" transform="translate(391.000000, 1463.000000)">
            <g id="编组-9备份" transform="translate(410.000000, 105.000000)">
              <g id="Free-Shipping-Worldwide" transform="translate(3.845822, 9.500000)">
                <path d="M54.6182518,5.69498626 C49.9059309,2.12125722 44.0266998,0 37.6508826,0 C22.1513305,0 9.5864609,12.536027 9.5864609,28 C9.5864609,43.463973 22.1513305,56 37.6508826,56 C52.2373657,56 64.2247426,44.8974483 65.5864609,30.7004725" id="路径" stroke="#000000" stroke-width="2.2" stroke-linecap="round"></path>
                <path d="M5.10937949,28 C0.613659954,32.6747531 -0.915237361,36.338537 0.522687547,38.9913515 C1.96061246,41.6441661 12.8342012,39.5385334 33.1434539,32.6744533" id="路径-11" stroke="#000000" stroke-width="2.2" stroke-linecap="round"></path>
                <path d="M34.0870162,21.5243548 C38.8880198,27.9000985 42.6921946,30.8593834 45.4995404,30.4022096 C48.3068863,29.9450357 59.5172773,24.2924949 79.1307136,13.4445871 C75.2678424,10.8660001 72.1872776,10.0437655 69.8890189,10.9778833 C66.4416309,12.3790599 64.8375871,15.5218114 60.2639494,14.8852952 C55.6903117,14.248779 48.5221786,9.5767066 44.6538367,10.9778833 C43.9421649,11.2437766 43.1750082,11.5287981 42.3523666,11.8329476 L49.1810787,18.1551466 C50.7387256,20.2579981 50.7387256,21.585507 49.1810787,22.1376732 C46.8446083,22.9659224 43.7531906,22.6205857 42.3523666,21.5243548 C41.4184838,20.7935341 38.663367,20.7935341 34.0870162,21.5243548 Z" id="路径-13" fill="#D6D6D6"></path>
                <path d="M34.0870162,21.5243548 C38.8880198,27.9000985 42.6921946,30.8593834 45.4995404,30.4022096 C48.3068863,29.9450357 59.5172773,24.2924949 79.1307136,13.4445871 C75.2678424,10.8660001 72.1872776,10.0437655 69.8890189,10.9778833 C66.4416309,12.3790599 64.8375871,15.5218114 60.2639494,14.8852952 C55.6903117,14.248779 48.5221786,9.5767066 44.6538367,10.9778833 C43.9421649,11.2437766 43.1750082,11.5287981 42.3523666,11.8329476 L49.1810787,18.1551466 C50.7387256,20.2579981 50.7387256,21.585507 49.1810787,22.1376732 C46.8446083,22.9659224 43.7531906,22.6205857 42.3523666,21.5243548 C41.4184838,20.7935341 38.663367,20.7935341 34.0870162,21.5243548 Z" id="路径-13" stroke="#000000" stroke-width="2.2" stroke-linecap="round"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "free-shipping-worldwide",
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {}
}
</script>

<style scoped lang='less'>

</style>
