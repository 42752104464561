<script setup>
import { defineComponent, reactive, toRefs, ref, onMounted, watch } from "vue";
import { Top } from "@element-plus/icons";
import { referList } from "@/api/affiliate.js";
import { ElMessage } from "element-plus";

let props = defineProps({
  examine: {
    type: Object,
    default: () => ({}),
  },
});

onMounted(() => {
  if (
    Number(props.examine.status) === 1 ||
    Number(props.examine.status) === 3
  ) {
    getReferList();
  }
});

let page = ref("1");
const tableData = ref([]);
let meta = ref({});
let getReferList = async () => {
  try {
    const { data } = await referList({
      type: activeColor.value,
      email: keyword.value || undefined,
      page: page.value,
    });
    tableData.value = data?.data;
    meta.value = data?.meta;
  } catch (error) {
    ElMessage.error(error);
  }
};

//回到顶部
let backTop = () => {
  let top = document.documentElement.scrollTop || document.body.scrollTop;
  // 实现滚动效果
  const timeTop = setInterval(() => {
    document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
    if (top <= 0) {
      clearInterval(timeTop);
    }
  }, 10);
};

let activeColor = ref("1");

//激活
let setActivated = () => {
  activeColor.value = "1";
  page.value = 1;
  getReferList();
};

//未激活

let setUnactivated = () => {
  activeColor.value = "2";
  page.value = 1;
  getReferList();
};

//失效

let setHasExpired = () => {
  activeColor.value = "3";
  page.value = 1;
  getReferList();
};

//关键字搜索
let keyword = ref("");

let searchKeyword = () => {
  page.value = 1;
  getReferList();
};

//关键字为空
let searchChange = (e) => {
  if (!e) {
    keyword.value = "";
    page.value = 1;
    getReferList();
  }
};

//分页
let currentChange = (e) => {
  page.value = e;
  getReferList();
};
</script>

<template>
  <div class="w1120 referrals">
    <div class="referrals_text padding_left_right">Referrals</div>
    <div class="flex flex_space">
      <div class="padding_left_right">
        <el-button
          type="info"
          class="btn_active"
          round
          :class="{ active: activeColor === '1' }"
          @click="setActivated"
          >Activated</el-button
        >
        <el-button
          type="info"
          class="btn_active"
          round
          :class="{ active: activeColor === '2' }"
          @click="setUnactivated"
          >Unactivated</el-button
        >
        <el-button
          type="info"
          class="btn_active"
          round
          :class="{ active: activeColor === '3' }"
          @click="setHasExpired"
          >Has Expired</el-button
        >
      </div>
      <div class="flex padding_left_right">
        <el-input
          @change="searchChange"
          v-model="keyword"
          style="width: 360px; margin-right: 20px"
          placeholder="Email "
          clearable
          @keyup.enter="searchKeyword"
        ></el-input>
        <el-button type="primary" @click="searchKeyword" style="height: 40px"
          >Search</el-button
        >
      </div>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="email" label="Email" align="center" width="250" />
      <el-table-column
        prop="affiliate.pOrders"
        align="center"
        label="Pending Orders"
        width="135"
      />
      <el-table-column
        prop="affiliate.pAmount"
        align="center"
        min-width="135"
        label="Pending Amount"
      />
      <el-table-column
        prop="affiliate.eOrders"
        align="center"
        min-width="120"
        label="Earned Orders"
      />
      <el-table-column
        prop="affiliate.eAmount"
        align="center"
        min-width="125"
        label="Earned Amount"
      />
      <el-table-column
        prop="created_at"
        align="center"
        label="Register Time"
        min-width="180"
      />
    </el-table>

    <el-pagination
      background
      layout="prev, pager, next"
      :total="meta.total"
      :page-size="meta.per_page"
      @current-change="currentChange"
      :hide-on-single-page="true"
    />
    <div class="back_top" @click="backTop">
      Back up to top<el-icon class="top_icon"><top /></el-icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
.active {
  background: #000000;
  color: #ffffff;
}
.back_top {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  cursor: pointer;
  margin-top: 60px;
  .top_icon {
    font-size: 21px;
    margin-left: 12px;
  }
}
:deep(.el-pagination) {
  text-align: center;
  margin-top: 30px;
}
.padding_left_right {
  padding: 0 24px;
}
.flex_space {
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid #ebeef5;
}
.w1120 {
  width: 1120px;
}
.referrals {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 24px 0;
  &_text {
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 33px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1350px) {
  .btn_active {
    margin-bottom: 10px;
  }
}
</style>
