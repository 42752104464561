<script setup>
import { reactive, onMounted, ref } from "vue";
import SubmittedSuccessfully from "./components/submittedSuccessfully";
import affiliateProgramHead from "./components/affiliateProgramHead";
import affiliateProgramOperating from "./components/affiliateProgramOperating";
import spread from "./components/spread";
import examineTable from "./components/examineTable";
import moreDetails from "./components/moreDetails";
import approveHead from "./components/approveHead";
import incomeEcharts from "./components/incomeEcharts";
import referralsTable from "./components/referralsTable";
import forbidden from "./components/forbidden";
import copyrightInfo from "@/components/copyrightInfo";
import setLoading from "@/components/setLoading";
import { ElMessage } from "element-plus";
import { check, totalData } from "@/api/affiliate.js";

onMounted(() => {
  getCheck();
});

let loadingShow = ref(false);

let getCheck = async () => {
  loadingShow.value = true;
  try {
    const { data } = await check();
    examine.status = data?.status;
    if (Number(data?.status) === 1 ||Number(data?.status) === 3 ) {
      getTotalData(data?.status);
    } else {
      loadingShow.value = false;
    }
    // loadingShow.value = false;

  } catch (error) {
    loadingShow.value = false;
    ElMessage.error(error );
  }
};

let totalObj = reactive({
  baseInfo: {},
  subordinate: {},
  chart: [],
});
let promotionCode = ref(""); //推荐url
let getTotalData = async (type) => {
  try {
    const { data } = await totalData({
      type: type,
    });
    totalObj.baseInfo = data?.baseInfo;
    totalObj.subordinate = data?.subordinate;
    promotionCode.value = data?.baseInfo?.promotion_code;
    filterStatus(data?.chart);
  } catch (error) {
    ElMessage.error(error);
  }
};

// 1冻结2结算
let freezeMoney = ref([]); //冻结金额
let settleMoney = ref([]); //结算金额
let times = ref([]);
function filterStatus(list) {
  if (Array.isArray(list)) {
    list.forEach((val) => {
      if (Number(val.status) === 1) {
        //冻结金额
        freezeMoney.value.push(val.commission_money);
      } else {
        //结算金额
        settleMoney.value.push(val.commission_money);
      }

      times.value.push(val.updated_at.substring(0,11));
    });
    //时间去重
    times.value = [...new Set(times.value)];
    loadingShow.value = false;
  } else {
    ElMessage.error("Echarts Data Error");
  }
}

//type 1:最近7天,2:最近30天,3:最近90天,4:最近180天

let changeType = (e) => {
  freezeMoney.value = [];
  settleMoney.value = [];
  times.value = [];
  getTotalData(e);
};

/**
 * status
 * 4：未申请-展示申请form表单OR推广人计划说明页面
  3：禁用-展示禁用标识
  2：审核失败-展示申请form表单页面
  1：审核成功-唤起推广总数据+下线列表接口
  0：待审核-展示待审核标识
 */

let examine = reactive({
  examineShow: false, //审核表显示
  status: "4",
});
// 前往审核表
let applyNow = () => {
  examine.examineShow = true;
};

//从审核表返回
let back = () => {
  examine.examineShow = false;
};
</script>

<template>
  <div v-show="!loadingShow">
    <!-- 审核中 -->
    <SubmittedSuccessfully v-if="Number(examine.status) === 0" />

    <div class="main">
      <!-- 根据是否审核判断上边距 -->
      <div
        class="w1120 bg"
        :style="{
          'margin-top':
            Number(examine.status) === 4 || Number(examine.status) === 2
              ? '60px'
              : '20px',
        }"
        v-if="
          Number(examine.status) === 4 ||
          Number(examine.status) === 0 ||
          Number(examine.status) === 2
        "
      >
        <template v-if="!examine.examineShow">
          <affiliateProgramHead @applyNow="applyNow"/>
          <affiliateProgramOperating />
          <spread />
          <moreDetails @applyNow="applyNow" :examine="examine" />
        </template>
        <template v-else>
          <examineTable @back="back" @update="getCheck" />
        </template>
      </div>

      <!-- 审核通过 -->
      <div v-if="Number(examine.status) === 1 || Number(examine.status) === 3">
        <!-- 禁用 -->
        <forbidden v-if="Number(examine.status) === 3" />

        <approveHead :promotionCode="promotionCode" />
        <incomeEcharts
          :totalObj="totalObj"
          @changeType="changeType"
          :freezeMoney="freezeMoney"
          :settleMoney="settleMoney"
          :times="times"
        />
        <referralsTable :examine="examine"/>
      </div>
    </div>

    <!-- 底部 -->
    <copyrightInfo />
  </div>
  <div v-show="loadingShow">
    <setLoading />
  </div>
</template>

<style scoped lang="scss">
.main {
  display: flex;
  justify-content: center;
}
.bg {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.w1120 {
  width: 1120px;
}
</style>
