<template>
  <div>
    <svg width="56px" height="69px" viewBox="0 0 56 69" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <!-- Generator: Sketch 63 (92445) - https://sketch.com -->
      <title>Template Design</title>
      <desc>Created with Sketch.</desc>
      <g id="工作原理" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Affiliate-Program5" transform="translate(-1213.000000, -1567.000000)" stroke="#050000" stroke-width="2">
          <g id="编组-7备份" transform="translate(391.000000, 1463.000000)">
            <g id="编组-9备份-2" transform="translate(820.000000, 105.000000)">
              <g id="Template-Design" transform="translate(2.000000, 0.000000)">
                <rect id="矩形" x="1" y="4" width="50" height="63" rx="3"></rect>
                <path d="M3,4.04475886 L3,3 C3,1.34314575 4.34314575,0 6,0 L52,0 C53.6568542,0 55,1.34314575 55,3 L55,62 C55,63.6568542 53.6568542,65 52,65 L51.1384332,65" id="路径"></path>
                <g id="renxiantu" transform="translate(12.000000, 11.000000)">
                  <path d="M14.9732079,24.5 C12.6332082,24.5 11.0554809,25.7431597 9.97532058,27.3657429 C7.75808216,30.6964096 7.50164236,35.5872049 7.50164236,36.2257225 C7.50164236,37.1263522 7.23099751,38.1637534 6.36735528,39.2613701 C7.18414743,39.326691 7.97399153,39.2042911 8.66595461,39.1117404 C9.49345956,39.0010607 10.2190989,38.9263918 10.7811102,39.0258386 C11.472163,39.148119 11.9862578,39.4795377 12.3193964,40.09345 C12.6581553,40.7177193 12.802492,41.5322577 12.6782894,42.3844921 C12.5710914,43.120046 12.2657707,43.8818178 11.7330609,44.5491438 C10.9258484,45.5603396 9.58793148,46.3839924 7.52343841,46.4772644 C6.30668043,46.4772644 5.31978959,46.8562669 4.58374632,47.6588456 C3.95856085,48.3405454 3.51270229,49.2927457 3.21597831,50.4986542 C13.4661803,50.4880407 21.2886803,50.4877299 26.6828171,50.4977566 C26.5344884,49.6885453 26.1899618,48.9135597 25.5865008,48.2552266 C24.8250855,47.4245766 23.6687694,46.7922855 22.0487511,46.4564451 C20.1184735,46.0562859 19.0385071,45.0448376 18.4707004,43.9910243 C18.0261286,43.1659273 17.8981559,42.2938447 17.9594972,41.5901368 C18.0028402,41.092906 18.1426726,40.6726491 18.3195402,40.3725367 C18.6665072,39.7837963 19.153806,39.5704002 19.6078666,39.5704002 C19.8035203,39.5704002 20.0807917,39.5955604 20.4172807,39.6292404 C21.2128789,39.7088737 22.4809158,39.8845464 23.3961235,39.2427116 C22.903659,38.5945412 22.5552205,37.6342455 22.4147661,36.2760237 C22.2128446,32.2668535 21.4819885,29.2430464 20.1495988,27.2341561 C18.9379504,25.4073117 17.2102704,24.5 14.9732079,24.5 Z" id="路径-12" fill="#D8D8D8"></path>
                  <circle id="椭圆形" cx="3.5" cy="18" r="2.5"></circle>
                  <circle id="椭圆形备份" fill="#D8D8D8" cx="25.5" cy="7.5" r="6.5"></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "template-design",
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {}
}
</script>

<style scoped lang='less'>

</style>
