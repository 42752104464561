<script setup>
import { defineComponent, reactive, toRefs } from "vue";
import img3 from "@/assets/affiliateProgram/3.png";
import img4 from "@/assets/affiliateProgram/4.png";
import img5 from "@/assets/affiliateProgram/5.png";
import img6 from "@/assets/affiliateProgram/6.png";
let obj = reactive({
  list: [
    {
      id: 1,
      title: "Apply",
      description:
        "Register a 99diy account, apply for the 99diy Affiliate Program.",
      url: img3,
      style: "width:205px ;height:200px ;",
    },
    {
      id: 2,
      title: "Get the unique URL",
      description: "Example: 99diy.com/?IC=YkPDX10Mi",
      url: img4,
      style: "  width:189px ;height:203px ;",
    },
    {
      id: 3,
      title: "Promote",
      description: "Share or spread your own URL to your potential customers.",
      url: img5,
      style: "  width:188px ;height:221px ;",
    },
    {
      id: 4,
      title: "Earn",
      description:
        "Get 10% commission from your customers on each order for 12 consecutive months",
      url: img6,
      style: "  width:193px ;height:223px ;",
    },
  ],
});
</script>

<template>
  <div class="box">
    <div class="title">How it work</div>
    <div class="step">
      <div class="step_box" v-for="item in obj.list" :key="item.id">
        <div class="step_box_img">
          <el-image
            :style="item.style"
            :src="item.url"
            :alt="item.title"
          ></el-image>
        </div>
        <div class="step_box_title">{{ item.title }}</div>
        <div class="step_box_description">{{ item.description }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title {
  font-size: 32px;
  font-weight: 600;
  color: #000000;
  line-height: 45px;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 55px;
}
.step {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &_box {
    width: 205px;
    margin-right: 60px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    &_img {
      height: 270px;
      width: 240px;
    }
    &_title {
      font-size: 21px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 29px;
    }
    &_description {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 26px;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .step {
    justify-content: center;
    &_box {
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .step {
    &_box {
      margin-right: 0;
    }
  }
}
</style>
